import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import MenuItem from '@material-ui/core/MenuItem';
import '../../App.css';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Exchange from '../img/exchange.png';
import axios from "axios";
import Footer from "../footer.js";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ReactSpeechRecognitionComponent from "./voiceRecognition.js"


const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important",
        borderRadius: "10px"
    },
    content: {
        //paddingBottom: "10%",
        paddingTop: "20px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    select: {
        paddingLeft: "10px",
        borderRadius: "55px"
      },
    textField: {
        background: "#F5F5F5",
        width: "50%",
        marginLeft: "5px"
      }

}));



const VoiceDictionary = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { transcript, listening, resetTranscript } = useSpeechRecognition();

    const voiceTranslate = () => {
        const base_url = process.env.REACT_APP_BACKEND_URL;
        //const base_url = "http://localhost:5000";
        var p1 = document.getElementById('p1');
        var words = transcript; // ReactSpeechRecognitionComponentで使われているtranscriptを使う
        //要翻訳の言葉
        var pattern = new RegExp("[`~%!@#^=''?~！@#￥……&——‘”“'？*()（），,。.、]");//末尾の記号を取り除く
        if (pattern.test(words.substr(-1,1)))
            words = words.substring(0, words.length - 1);
        var language = document.getElementById('select1').textContent; // 選択された言語
        if (p1.textContent === "標 準 日 本 語") {
            language = language + "反";
        }
        const request = {
            words: words,
            language: language
        }
   
        if (language === "和歌山弁" || language === "和歌山弁反") {
            axios.post(base_url + '/wakayamaLibrary/trans', request).then((response) => {
                const data = response.data;
                console.log("Received data from wakayamaLibrary:", data);
                const text2 = document.getElementById('text2');
                console.log(data);
                text2.value = data;
            }).catch((err) => {
                console.log(err);
            });
        } else if (language === "沖縄弁" || language === "沖縄弁反") {
            axios.post(base_url + '/okinawaLibrary/trans', request).then((response) => {
                const data = response.data;
                console.log("Received data from okinawaLibrary:", data);
                const text2 = document.getElementById('text2');
                //console.log(data);
                text2.value = data;
            }).catch((err) => {
                console.log(err);
            });
        }
    }
   
    const exchange = () => {
        var text = document.getElementById('select1').textContent;
        var p1 = document.getElementById('p1');
        var p2 = document.getElementById('p2');
        var p3 = p1.textContent;
        var p4 = p2.textContent;
        p1.textContent = p4;
        p2.textContent = p3;
    }
   


   useEffect(() => {
        if (transcript) {
            document.getElementById('text1').value = transcript;
        }
    }, [transcript]);

    return (
        <div>
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>
                        <Typography className={classes.title} variant="h5" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                            {t('translate')}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <div className={classes.content}>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: "300px", paddingLeft: "10px", borderRadius: "40%" }}>
                        <Select className={classes.select} id="select1" defaultValue={1}>
                            <MenuItem value={1}>和歌山弁</MenuItem>
                            <MenuItem value={2}>沖縄弁</MenuItem>
                        </Select>
                    </FormControl>
                    <Link to="/homepage">
                        <Button className={classes.button} variant="contained" color="primary" disableElevation style={{ backgroundColor: "#B4C0CB" }}>
                            {t('back')}
                        </Button>
                    </Link>
                    <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={voiceTranslate}>
                        {t('translate')}
                    </Button>
                    <br></br>
                    <br></br>

                    <ReactSpeechRecognitionComponent />
                  

                    <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                        <div style={{ flex: "1", marginRight: "10px" }}>
                            <div style={{ width: "100%" }}>
                                <p id="p1" style={{ padding: "5px", fontSize: "10px", display: "inline" }}>方 言 文 句</p>
                                <br />
                                <TextField className={classes.textField} id="text1" multiline variant="outlined" rows={10} style={{ width: "100%", paddingLeft: "5px", borderRadius: "30px" }} />
                            </div>
                        </div>
                        <img
                            src={Exchange}
                            style={{
                                width: "50px",
                                cursor: "pointer",
                                height: "50px",
                                margin: "5px",
                                marginTop: "30px",
                                borderRadius: "5px"
                            }}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={exchange}
                        />
                       <div style={{ flex: "1", marginLeft: "10px" }}>
                           <div style={{ width: "100%" }}>
                               <p id="p2" style={{ padding: "5px", fontSize: "10px", display: "inline" }}>標 準 日 本 語</p>
                               <br />
                               <TextField className={classes.textField} id="text2" InputProps={{ readOnly: true }} multiline variant="outlined" rows={10} style={{ background: "#F5F5F5", width: "100%", marginLeft: "5px" }} />
                            </div>
                        </div>
                    </div>


                </div>
            </Container>
            <Footer />
        </div>
    );
}

export default VoiceDictionary;


