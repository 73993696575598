import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';         // 导入 AppBar
import Toolbar from '@material-ui/core/Toolbar';       // 导入 Toolbar
import Typography from '@material-ui/core/Typography'; // 导入 Typography
import FormControl from '@material-ui/core/FormControl';// 导入 FormControl
import Footer from "../footer"; // 导入 Footer 组件
import Container from '@material-ui/core/Container'; // 导入 Container
import CssBaseline from '@material-ui/core/CssBaseline'; // 导入 CssBaseline
import okinawaLibraryData from './data/okinawalibrarys.json';  // 加载本地 JSON 文件
import okinawaPhrasesData from './data/okinawaPhrases.json';   // 加载本地 JSON 文件
import okinawaWordsData from './data/okinawawords.json';       // 加载本地 JSON 文件
import Exchange from '../img/exchange.png'; // 导入 exchange 图标
import Nav from "../nav"; // 导入 Nav 组件

// 使用 makeStyles 来定义样式
const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
        marginLeft: "25px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        paddingTop: "20px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

function Dictionary() {
    const classes = useStyles(); // 使用定义的样式
    const [language, setLanguage] = useState("1"); // 设置选择的语言的状态

    // 处理语言选择的变化
    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <div>                
            <Nav /> {/* 导航组件 */}
            <div>
                {/* 顶部的 AppBar 显示翻译功能 */}
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>
                        <Typography variant="h5" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                            translate
                        </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <Container component="main" maxWidth="lg">
                <CssBaseline />
                <div className={classes.content}>
                    {/* 下拉框用于选择方言 */}
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: "300px", paddingLeft: "10px", borderRadius: "40%" }}>
                        <Select id="select1" value={language} onChange={handleLanguageChange}>
                            <MenuItem value="1">和歌山弁</MenuItem >
                            <MenuItem value="2">沖縄弁</MenuItem >
                        </Select>
                    </FormControl>
                    {/* 翻译按钮 */}
                    <Button className={classes.button} variant="contained" color="primary" onClick={translate}>
                        translate
                    </Button>
                    <br /><br />
                    <div>
                        <div style={{ width: "90%" }}>
                            {/* 输入区域 */}
                            <p id="p1" style={{ padding: "20px", fontSize: "20px", display: "inline" }}>標準日本語</p>  
                            <br />
                            {/* 输入框，用于输入要翻译的句子 */}
                            <TextField id="text1" multiline label="Input Your Sentence" variant="outlined" rows={10} style={{ width: "100%", paddingLeft: "15px", borderRadius: "55px", }} />                           
                            {/* 交换按钮 */}
                            <img src={Exchange} style={{
                                width: "60px", cursor: "pointer", height: "60px", margin: "8px", marginLeft: "50%", marginTop: "30px", borderRadius: "10px"
                            }} alt="exchange" onClick={exchange} />
                        </div>   
                        <div style={{ width: "90%", paddingTop: "20px" }}>
                            {/* 输出区域 */}
                            <p id="p2" style={{ padding: "20px", fontSize: "20px", display: "inline" }}>方言文句</p>
                            <br />
                            {/* 输出框，用于显示翻译结果 */}
                            <TextField id="text2" InputProps={{
                                readOnly: true,
                            }} multiline variant="outlined" rows={10} style={{ background: "#F5F5F5", width: "100%", marginLeft: "15px" }} />
                        </div>
                    </div>
                </div>
            </Container>
            <Footer /> {/* 页脚 */}
        </div>
    );
}

export default Dictionary;

// 翻译函数，根据选择的语言进行翻译
function translate() {
    var language = document.getElementById('select1').value; // 获取选择的语言
    var words = document.getElementById('text1').value; // 获取输入的句子
    var p1 = document.getElementById('p1').textContent; // 获取 p1 的内容
    var p2 = document.getElementById('p2').textContent; // 获取 p2 的内容

    // 判断翻译方向
    if (p1 === "標準日本語" && p2 === "方言文句") {
        language = "沖縄弁反"; 
    } else if (p1 === "方言文句" && p2 === "標準日本語") {
        language = "沖縄弁"; 
    }

    // 根据语言选择，调用不同的翻译函数
    let translationResult = "";
    if (language === "沖縄弁反") {
        translationResult = translateFromStandardToOkinawan(words);
    } else if (language === "沖縄弁") {
        translationResult = translateFromOkinawanToStandard(words);
    }

    // 将翻译结果显示在 p2 文本框中
    document.getElementById('text2').value = translationResult;
}


// 标准日语到冲绳方言的翻译
/*function translateFromStandardToOkinawan(words) {
    let result = "";
    let wordList = words.split(/\s+/); // 按空格分割输入的句子
    
    wordList.forEach(word => {
        // 先在短语数据中查找
        let foundWord = okinawaPhrasesData.find(item => item.phraseAft === word);
        if (foundWord) {
            result += foundWord.phraseOri + " "; // 如果找到匹配的短语，替换
        } else {
            // 如果找不到，尝试在单词库中查找
            foundWord = okinawaLibraryData.find(item => item.meaning === word);
            result += foundWord ? foundWord.word + " " : word + " "; // 如果找到对应单词，替换
        }
    });

    return result.trim(); // 返回翻译结果
}*/


// 标准日语到冲绳方言的翻译
function translateFromStandardToOkinawan(words) {
    // 优先查找整句是否存在于短句数据库中
    let foundPhrase = okinawaPhrasesData.find(item => item.phraseAft === words);
    if (foundPhrase) {
        return foundPhrase.phraseOri; // 如果找到匹配的短句，直接返回
    }

    let result = "";
    let wordList = words.split(/\s+/); // 按空格分割输入的句子

    wordList.forEach(word => {
        // 先在短语数据中查找
        let foundWord = okinawaPhrasesData.find(item => item.phraseAft === word);
        if (foundWord) {
            result += foundWord.phraseOri + " "; // 如果找到匹配的短语，替换
        } else {
            // 如果找不到，尝试在单词库中查找
            foundWord = okinawaLibraryData.find(item => item.meaning === word);
            result += foundWord ? foundWord.word + " " : word + " "; // 如果找到对应单词，替换
        }
    });

    return result.trim(); // 返回翻译结果
}


// 冲绳方言到标准日语的翻译
function translateFromOkinawanToStandard(words) {
    let result = "";

    // 优先查找整句是否存在于短句数据库中
    let foundPhrase = okinawaPhrasesData.find(item => item.phraseOri === words);
    if (foundPhrase) {
        return foundPhrase.phraseAft; // 如果找到匹配的短句，直接返回
    }

    // 如果没有找到整句匹配，再按单词进行逐一查找
    let wordList = words.split(/\s+/); // 按空格分割输入的句子

    wordList.forEach(word => {
        // 先在短语数据中查找
        let foundWord = okinawaPhrasesData.find(item => item.phraseOri === word);
        if (foundWord) {
            result += foundWord.phraseAft + " "; // 如果找到匹配的短语，替换
        } else {
            // 如果找不到，尝试在单词库中查找
            foundWord = okinawaLibraryData.find(item => item.word === word);
            result += foundWord ? foundWord.meaning + " " : word + " "; // 如果找到对应单词，替换
        }
    });

    return result.trim(); // 返回翻译结果
}


// 冲绳方言到标准日语的翻译
/*function translateFromOkinawanToStandard(words) {
    let result = "";
    let wordList = words.split(/\s+/); // 按空格分割输入的句子
    
    wordList.forEach(word => {
        // 先在短语数据中查找
        let foundWord = okinawaPhrasesData.find(item => item.phraseOri === word);
        if (foundWord) {
            result += foundWord.phraseAft + " "; // 如果找到匹配的短语，替换
        } else {
            // 如果找不到，尝试在单词库中查找
            foundWord = okinawaLibraryData.find(item => item.word === word);
            result += foundWord ? foundWord.meaning + " " : word + " "; // 如果找到对应单词，替换
        }
    });

    return result.trim(); // 返回翻译结果
}*/

// 交换输入输出的内容
function exchange() {
    var text1 = document.getElementById('text1').value;
    var text2 = document.getElementById('text2').value;

    // 交换输入和输出框中的内容
    document.getElementById('text1').value = null;
    document.getElementById('text2').value = null;

    // 交换标签
    var p1 = document.getElementById('p1');
    var p2 = document.getElementById('p2');
    var tempText1 = p1.textContent;
    var tempText2 = p2.textContent;
    p1.textContent = tempText2;
    p2.textContent = tempText1;
}





/*import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import MenuItem from '@material-ui/core/MenuItem';
import '../../App.css';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Exchange from '../img/exchange.png';
import axios from "axios";
import Footer from "../footer";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';



const useStyles = makeStyles((theme) => ({//使用material-ui的makeStyles创建自定义样式
    button: {
        margin: "8px",
        marginLeft: "25px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important", 
        borderRadius: "10px"
    },
    content: {
        //paddingBottom: "10%",
        paddingTop: "20px"
    },
    div2: {
        padding: "10px",
        float: "left"
    },

    a: {
        padding: "3px",
        marginTop: "5px",
        textDecoration: "underline",
        display:"inline"
    },

    p: {
        textAlign: "center",
        fontSize: "22px",
        display: "inline"
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }

}));



function Dictionary() {//dictionary组件定义了主ui结构，包含导航栏，选择框，按钮和文本区域

    const classes = useStyles();
    const { t, i18n } = useTranslation();
   
    return (
            <div>                
                 <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h5" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                {t('translate')}
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                <div className={classes.content}>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: "300px", paddingLeft: "10px", borderRadius: "40%" }}>
                        <Select className={classes.select} id="select1" defaultValue={1}>
                            <MenuItem value="1" style={{ paddingLeft: "10px", borderRadius: "55px" }}>和歌山弁</MenuItem >
                            <MenuItem value="2" style={{ paddingLeft: "10px", borderRadius: "55px" }}>沖縄弁</MenuItem >
                        </Select>
                    </FormControl>
                    <Link to="/homepage"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{  backgroundColor: "#B4C0CB" }}> {t('back')}</Button></Link>
                    <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={translate}> {t('translate')}</Button>
                    <br></br>
                    <br></br>

                    <div>
                        <div style={{ width: "90%" }}>
                            <p id="p1" style={{ padding: "20px", fontSize: "20px", display: "inline" }}>標準日本語</p>  
                            <br></br>
                            <TextField className={classes.textField} id="text1" multiline label="Input your sentences" variant="outlined" rows={10} style={{ width: "100%", paddingLeft: "15px", borderRadius: "55px", }} />                           
                            <img src={Exchange} style={{
                                width: "60px", cursor: "pointer", height: "60px", margin: "8px", marginLeft: "50%", marginTop: "30px", borderRadius: "10px"
                            }} variant="contained" color="primary" disableElevation onClick={exchange}></img>  
                        </div>   
                        <div style={{ width: "90%", paddingTop: "20px"}}>
                            <p id="p2" style={{ padding: "20px", fontSize: "20px", display: "inline" }}>方言文句</p>
                            <br></br>
                            <TextField className={classes.textField} id="text2" InputProps={{
                                readOnly: true,
                                }} multiline variant="outlined" rows={10} style={{ background: "#F5F5F5", width: "100%", marginLeft: "15px" }} />
                        </div>
                    </div>
                </div>
                </Container>
                <Footer />
            </div>
   );
   }

export default Dictionary;//导出dictionary组件



function translate() {
    const base_url = process.env.REACT_APP_BACKEND_URL; // 从环境变量中获取后端URL
    //const base_url = process.env.REACT_APP_LOCAL_URL;
    var language = document.getElementById('select1').textContent; // 选择的语言//選択された言語
    var p1 = document.getElementById('p1'); // 获取 p1 标签内容
    var words = document.getElementById('text1').value; // 获取 p1 文本框中的内容
    var p2 = document.getElementById('p2'); // 获取 p2 标签内容
    var pattern = new RegExp("[`~%!@#^=''?~！@#￥……&——‘”“'？*()（），,。.、]");

    // 去掉末尾的标点符号
    /*if (pattern.test(words.substr(-1, 1))) {
        words = words.substring(0, words.length - 1);
    }

    // 判断翻译方向
    if (p1.textContent === "標準日本語" && p2.textContent === "方言文句") {
        language = "沖縄弁反"; 
        
    } else if (p1.textContent === "方言文句" && p2.textContent === "標準日本語"){
        language = "沖縄弁"; 
    }
    

    const request = {
        words: words,
        language: language
    };

    // 根据选择的语言和翻译方向，调用不同的 API
    /*if (language === "和歌山弁" || language === "和歌山弁反") {
        axios.post(base_url + '/wakayamaLibrary/trans', request).then((response) => {
            const data = response.data;
            const text2 = document.getElementById('text2'); // 获取 p2 文本框
            text2.value = data; // 将翻译结果放入 p2
        }).catch((err) => {
            console.log(err);
            alert("翻译失败，请稍后重试！"); // 提示用户翻译出错
        });
    } else 
    if (language === "沖縄弁" || language === "沖縄弁反") {
        axios.post(base_url + '/okinawaLibrary/trans', request).then((response) => {
            const data = response.data;
            const text2 = document.getElementById('text2'); // 获取 p2 文本框
            text2.value = data; // 将翻译结果放入 p2
        }).catch((err) => {
            console.log(err);
            alert("翻译失败，请稍后重试！"); // 提示用户翻译出错
        });
    }
}


function exchange() {
    var text1 = document.getElementById('text1').value;
    var text2 = document.getElementById('text2').value;

    // 交换文本框内容
    document.getElementById('text1').value = null;
    document.getElementById('text2').value = null;

    // 交换标签内容
    var p1 = document.getElementById('p1');
    var p2 = document.getElementById('p2');
    var tempText1 = p1.textContent;
    var tempText2 = p2.textContent;
    p1.textContent = tempText2;
    p2.textContent = tempText1;

    // 判断翻译方向
    var language = document.getElementById('select1').value;
    if (p1.textContent === "標準日本語" && p2.textContent === "方言文句") {
        language = "沖縄弁反"; // 明确设定翻译方向
    } else if (p1.textContent === "方言文句" && p2.textContent === "標準日本語"){
        language = "沖縄弁"; 
    }
}




/*function Dictionary() {//dictionary组件定义了主ui结构，包含导航栏，选择框，按钮和文本区域

    const classes = useStyles();
    const { t, i18n } = useTranslation();
   
    return (
            <div>                
                 <Nav />
                <div>
                    <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                        <Toolbar>
                            <Typography className={classes.title} variant="h5" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                                {t('translate')}
                         </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <Container component="main" maxWidth="lg">
                    <CssBaseline />
                <div className={classes.content}>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: "300px", paddingLeft: "10px", borderRadius: "40%" }}>
                        <Select className={classes.select} id="select1" defaultValue={1}>
                            <MenuItem value="1" style={{ paddingLeft: "10px", borderRadius: "55px" }}>和歌山弁</MenuItem >
                            <MenuItem value="2" style={{ paddingLeft: "10px", borderRadius: "55px" }}>沖縄弁</MenuItem >
                        </Select>
                    </FormControl>
                    <Link to="/homepage"><Button className={classes.button} variant="contained" color="primary" disableElevation style={{  backgroundColor: "#B4C0CB" }}> {t('back')}</Button></Link>
                    <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={translate}> {t('translate')}</Button>
                    <br></br>
                    <br></br>

                    <div>
                        <div style={{ width: "90%" }}>
                            <p id="p1" style={{ padding: "20px", fontSize: "20px", display: "inline" }}>方 言 文 句</p>  
                            <br></br>
                            <TextField className={classes.textField} id="text1" multiline label="Input your sentences" variant="outlined" rows={10} style={{ width: "100%", paddingLeft: "15px", borderRadius: "55px", }} />                           
                            <img src={Exchange} style={{
                                width: "60px", cursor: "pointer", height: "60px", margin: "8px", marginLeft: "50%", marginTop: "30px", borderRadius: "10px"
                            }} variant="contained" color="primary" disableElevation onClick={exchange}></img>  
                        </div>   
                        <div style={{ width: "90%", paddingTop: "20px"}}>
                            <p id="p2" style={{ padding: "20px", fontSize: "20px", display: "inline" }}>標 準 日 本 語</p>
                            <br></br>
                            <TextField className={classes.textField} id="text2" InputProps={{
                                readOnly: true,
                                }} multiline variant="outlined" rows={10} style={{ background: "#F5F5F5", width: "100%", marginLeft: "15px" }} />
                        </div>
                    </div>
                </div>
                </Container>
                <Footer />
            </div>
   );
   }

export default Dictionary;//导出dictionary组件*/

/*function translate() {//translate函数获取用户输入的句子，选择的语言，并进行翻译
    const base_url = process.env.REACT_APP_BACKEND_URL;//从环境变量中获取后端的基础URL
    //const base_url = "http://localhost:5000";
    //const base_url = "https://mip.medical-inclusion.academy"
    var p1 = document.getElementById('p1');//获取id为p1的元素
    var words = document.getElementById('text1').value; //要翻译的语句句（获取id为text1的输入值）//要翻訳の言葉
    var pattern = new RegExp("[`~%!@#^=''?~！@#￥……&——‘”“'？*()（），,。.、]");//去掉末尾符号//末尾の言葉を取り除く
    if (pattern.test(words.substr(-1,1)))//pattern：定义一个正则表达式，用于匹配特定的标点符号和特殊字符（如 ,、.、！ 等）。这些符号通常在句子末尾，不需要翻译，所以会被去掉。
        words = words.substring(0,words.length - 1);//words.substr(-1, 1)：获取用户输入句子的最后一个字符，并用正则表达式检查该字符是否是标点符号。如果是标点符号，则通过 substring 去掉这个符号
    var language = document.getElementById('select1').textContent; // 选择的语言//選択された言語
    if (p1.textContent == "方 言 文 句") {//p1.textContent 的内容用于确定翻译的方向。如果 p1.textContent 是 “方 言 文 句”，那么表示翻译方向是方言到标准日语，不做修改
        language = language ;
    }else if(p1.textContent == "標 準 日 本 語"){
        language =language+ "反";//如果是 “標 準 日 本 語”，则表示翻译方向是标准日语到方言，给 language 添加一个后缀“反”（后端用于识别反向翻译的标识符）
    }
    
    const request = {//request 是一个 JavaScript 对象，包含 words（用户输入的句子）和 language（用户选择的语言及翻译方向）。该对象将被用作发送给后端的翻译请求体
        words: words,
        language: language
    }

    if (language == "和歌山弁" || language == "和歌山弁反") {//根据选择的语言和内容决定请求的url，使用axios发送post请求到后端，接收翻译结果并更新页面内容
        axios.post(base_url + '/wakayamaLibrary/trans', request).then((response) => {//使用 axios.post 方法向后端 API 发送请求，URL 为 base_url + '/wakayamaLibrary/trans'，传递的请求体是 request
            const data = response.data;//axios 是一个用于发起 HTTP 请求的库，post 方法表示发送一个 POST 请求
            const text2 = document.getElementById('text2');//then 块：当请求成功时，后端返回的数据将作为响应。通过 response.data 获取到返回的翻译结果，并将结果放入 ID 为 text2 的输入框中
            console.log(data);
            text2.value = data;
        }).catch((err) => {
            console.log(err);
        });
    } else if (language == "沖縄弁" || language == "沖縄弁反") {
        axios.post(base_url + '/okinawaLibrary/trans', request).then((response) => {
            const data = response.data;
            const text2 = document.getElementById('text2');
            console.log(data);
            text2.value = data;
        }).catch((err) => {
            console.log(err);
        });
    }
}*/

/*function exchange() {//exchange函数交换输入和输出的文本内容以及对应的标签
    var text1 = document.getElementById('text1').value;
    var text2 = document.getElementById('text2').value;

    document.getElementById('text1').value = text2;
    document.getElementById('text2').value = text1;

    var p1 = document.getElementById('p1');
    var p2 = document.getElementById('p2');

    //var tempText = p1.textContent; 
    //p1.textContent = p2.textContent; 
    //p2.textContent = tempText;
    var tempText1 = p1.textContent; 
    var tempText2 = p2.textContent; 

    p1.textContent = tempText2;
    p2.textContent = tempText1;

    var language = document.getElementById('select1').textContent;
    if (p1.textContent == "標 準 日 本 語") {
        language = language + "反";    
    } 
}*/