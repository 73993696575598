import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Nav from "../nav.js";
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from "../footer";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "25px",
        marginLeft: "29px",
        color: "white",
        fontSize: "26px",
        backgroundColor: "#FFCC00",
        minHeight: "175px",
        minWidth: "300px !important",
        borderRadius: "46px",
        "&:hover": {
            background: "#FFE66F",
            textDecoration: "none"
        },
        "&:focus": {
            border: "none",
            outline: "none"
        }
    },
    button2: {
        margin: "8px",
        marginLeft: "25px",
        marginTop: "18px",
        color: "white",
        fontSize: "16px",
        backgroundColor: "#6CAFED",
        minHeight: "60px",
        minWidth: "160px !important",
        borderRadius: "10px"
    },
    link: {
        "&:hover": {
        textDecoration: "none",
        border:"none"
        },
        "&:focus": {
            border:"none",
            outline:"none"
        }  
      },
    content: {
        paddingBottom: "10%",
        paddingTop: "10%",
    }
}));

function Wakayama() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <div >
            <Nav />
            <div>
                <AppBar position="static" style={{ backgroundColor: "#F9B1B1", }}>
                    <Toolbar>

                        <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "10px", color: "black" }}>
                            Okinawa dialect
                         </Typography>
                    </Toolbar>
                </AppBar>
            </div>
            <Container component="main" maxWidth="lg">
             <CssBaseline />
            <div className={classes.content} style={{}}>

                <Link to="/okinawaBasicSentence" className={classes.link}><Button className={classes.button} variant="contained" color="primary" disableElevation > {t('Basic Sentences')}</Button></Link>
                <Link to="/okinawadic" className={classes.link}><Button className={classes.button} variant="contained" color="primary" disableElevation> {t('Dictionary')}</Button></Link>
                <Link to="/terms" className={classes.link}><Button className={classes.button} variant="contained" color="primary" disableElevation> {t('Medical Terms')}</Button></Link>
                <Link to="/VoiceTranslate" className={classes.link}><Button className={classes.button} variant="contained" color="primary" disableElevation> {t('Voice Input')}</Button></Link>
                <p className={classes.p}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <Link to="/dialect"><Button className={classes.button2} variant="contained" color="primary" disableElevation style={{ float: "right", backgroundColor: "#B4C0CB", marginRight: "20px" }} > {t('back')}</Button></Link>
                </div>
            {/*<div className={classes.foot}>
                <AppBar position="static" style={{ backgroundColor: "#eb5757b5", }}>
                    <Toolbar>
                        <Typography className={classes.title} variant="h6" noWrap style={{ paddingLeft: "133px" }}>
                            Copyright 2019 ETL (Eiko Takaoka Laboratory, Sophia University)
                            </Typography>
                    </Toolbar>
                </AppBar>
              </div>*/}
            </Container>
            <Footer />
        </div>
    );
}

export default Wakayama;


