import React , { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../App.css';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const useStyles = makeStyles((theme) => ({
  button: {
      margin: "8px",
      marginLeft: "25px",
      color: "white",
      fontSize: "16px",
      backgroundColor: "#6CAFED",
      minHeight: "60px",
      minWidth: "160px !important",
      borderRadius: "10px"
  },
  content: {
      //paddingBottom: "10%",
      paddingTop: "20px"
  },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  select: {
      paddingLeft: "10px",
      borderRadius: "55px"
    },
  textField: {
      background: "#F5F5F5",
      width: "50%",
      marginLeft: "5px"
    }

}));


const ReactSpeechRecognitionComponent = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>ブラウザが音声認識未対応です</span>;
  }

  return (
    <div>
      <p>入力: {ReactSpeechRecognitionComponent.listening ? "on" : "off"}</p>
      <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={() => SpeechRecognition.startListening()}>
      {t('start')}
      </Button>
      <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={() => SpeechRecognition.stopListening()}>
      {t('stop')}
      </Button>
      <Button className={classes.button} variant="contained" color="primary" disableElevation onClick={resetTranscript}>
      {t('reset')}
      </Button>
    </div>
  );
};

export default ReactSpeechRecognitionComponent;